.property-documents-view {
    .component-sub-container {
        border-radius: 10px;
        padding: 10px !important;
    }
    .component-header-main-card {
        .MuiCardContent-root {
            padding: 24px 0px !important;
        }
    }
}
.thumbnails-container{
    width: calc(30vw - 192px);
}
.theGapClass{
    gap: 20px;
}

@media (min-width: 1541px) {
    .theGapClass{
        gap: 23px;
    }
}
@media (min-width: 1741px) {
    .theGapClass{
        gap: 27px;
    }
}

@media (min-width: 1944px) {
    .theGapClass{
        gap: 29px;
    }
}

@media (min-width: 2300px) {
    .theGapClass{
        gap: 34px;
    }
}

@media (min-width: 2491px) {
    .theGapClass{
        gap: 46px;
    }
}