.property-forms-data-container {
    padding-top: 0px !important;
    .component-header-main-card {
        .MuiCardContent-root {
            padding-top: 0px;
        }
    }
}

.modal-form-upload {
    svg {
        height: 16px !important;
        width: 16px !important;
    }
}

.financial-forms {
    svg {
        height: 16px !important;
        width: 16px !important;
    }
}

.modification-form {
    svg {
        height: 16px !important;
        width: 16px !important;
    }
}

.modal-header {
    svg {
        height: 32px;
        width: 32px;
    }
}

.property-forms-upper-tabs {
    justify-content: end;
}

.property-center-div {
    width: 100%;
}

@media (max-width: 699px) {
    .back-icon {
        height: 20px !important;
        width: 20px !important;
    }
    .forward-icon {
        height: 20px !important;
        width: 20px !important;
    }
    .property-forms-container {
        .property-forms-header {
            padding-top: 12px;
        }
        .property-forms-helper-button {
            padding-bottom: 12px;
            .MuiTabs-root {
                margin-bottom: 12px;
            }
        }
        .property-forms-header {
            .property-forms-tab-container {
                justify-content: center;
            }
        }
        .property-forms-data-container {
            padding-top: 12px;
            svg {
                height: 16px;
                width: 16px;
            }
            .component-header-main-card {
                .MuiCardContent-root {
                    padding-top: 0px;
                }
            }
            .media-uploads {
                flex-direction: column;
                gap: 30px;
                height: 100%;
                .media-upload {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 700px) and (max-width: 1200px) {
    .back-icon {
        height: 25px !important;
        width: 25px !important;
    }
    .forward-icon {
        height: 25px !important;
        width: 25px !important;
    }
    .property-forms-upper-tabs {
        justify-content: center;
    }
    .property-forms-header {
        flex-direction: column;
        gap: 30px;
    }
    .property-forms-tab-container {
        justify-content: center;
    }
    .property-forms-helper-button {
        justify-content: center;
        padding-bottom: 0px;
    }
}

@media (min-width: 280px) and (max-width: 530px) {
    .back-icon {
        height: 12px !important;
        width: 12px !important;
    }
    .forward-icon {
        height: 17px !important;
        width: 17px !important;
    }
    .property-forms-upper-tabs {
        justify-content: center;
    }
    .property-center-div {
        width: 70%;
    }
    .property-forms-helper-button {
        padding-bottom: 12px;
        padding-top: 12px;
        .MuiTabs-root {
            margin: 8px;
        }
    }
    .property-forms-header {
        // padding-left: 12px !important;
        padding-top: 12px !important;
    }
    .property-forms-header-main-card {
        .MuiCardContent-root {
            padding: 12px;
        }
    }

    .property-forms-data-container {
        justify-content: center;
        padding-bottom: 8px;
        padding-top: 16px !important;
        // padding-left: 12px !important;
    }

    .property-profile-form {
        width: 100%;
        margin-left: 0px;
        .media-uploads {
            padding: 0;
            row-gap: 30px;
            height: 100%;
            flex-direction: column;
            .media-upload {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
