.internal-field{
    display: flex;
    width: 100%;
    max-width: 19%;
}
.modification-form{
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 899px) {
    .internal-field{
        display: flex;
        max-width: 100%;
    }
    .modification-form{
        display: flex;
        width: 100%;
    }
}