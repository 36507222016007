@media (min-width: 280px) and (max-width: 699px) {
    .upload-container {
        max-width: 100%;
        margin-left: 0px;
        width: 100%;
        flex-wrap: unset;
        flex-direction: revert;
        .avatar-upload-btn {
            padding-left: 8px;
            padding-top: 8px;
        }
    }
}

@media (min-width: 700px) and (max-width: 1200px) {
    .upload-container {
        flex-direction: column;
    }
    .upload-btn-container {
        display: flex;
        justify-content: center;
    }
    .warning-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.caption {
    display: flex;
    flex-direction: column;
}
