.main-layot {
    margin-right: 0px;
    .MuiContainer-root {
        height: 100%;
    }
    .MuiTableCell-head {
        font-weight: 700 !important;
    }
}

@media (max-width: 991px) {
    .main-layot {
        margin-right: 20px;
        padding: 0 !important;
        .MuiContainer-root {
            height: 100%;
        }
    }
}
@media (max-width: 599.95px) {
    .main-layot {
        margin-right: 10px;
    }
}