// @import '../../../assets/scss/themes-vars.module';

// input {
//     width: 14rem;
//     border: 3px solid $primaryMain;
//     padding: 5px 0px;
//     border-radius: 1px;
//     border-top: 0;
//     border-left: 0;
//     border-right: 0;
// }
// input:focus {
//     outline: none;
// }

.tab-option-client-form {
    svg {
        height: 24px;
        width: 24px;
    }
    span {
        text-align: initial;
    }
    .tab-header {
        font-weight: 600;
        font-size: 0.875rem;
    }
    .tab-sub-header {
        font-weight: 400;
        font-size: 0.75rem;
    }
}

.property-forms-data-container {
    padding-top: 0px !important;
    .component-header-main-card {
        .MuiCardContent-root {
            padding-top: 0px;
        }
    }
}

@media (max-width: 699px) {
    .property-forms-container {
        .property-forms-header {
            padding-top: 12px;
        }
        .property-forms-helper-button {
            padding-bottom: 12px;
            .MuiTabs-root {
                margin-bottom: 12px;
            }
        }
        .property-forms-header {
            .property-forms-tab-container {
                justify-content: center;
            }
        }
        .property-forms-data-container {
            padding-top: 12px;
            .component-header-main-card {
                .MuiCardContent-root {
                    padding-top: 0px;
                }
            }
            .media-uploads {
                flex-direction: column;
                gap: 30px;
                height: 100%;
                .media-upload {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 700px) and (max-width: 1200px) {
    .property-forms-header {
        flex-direction: column;
        gap: 30px;
    }
    .property-forms-tab-container {
        justify-content: center;
    }
    .property-forms-helper-button {
        justify-content: center;
        padding-bottom: 0px;
    }
}

@media (min-width: 280px) and (max-width: 530px) {
    .property-forms-helper-button {
        padding-bottom: 12px;
        padding-top: 12px;
        .MuiTabs-root {
            margin: 8px;
        }
    }
    .property-forms-header {
        padding-left: 12px !important;
        padding-top: 12px !important;
    }
    .property-forms-header-main-card {
        .MuiCardContent-root {
            padding: 12px;
        }
    }

    .property-forms-data-container {
        justify-content: center;
        padding-bottom: 8px;
        padding-top: 16px !important;
        padding-left: 12px !important;
    }

    .client-forms {
        width: 100%;
        margin-left: 0px;
        .media-uploads {
            padding: 0;
            row-gap: 30px;
            height: 100%;
            flex-direction: column;
            .media-upload {
                width: 100%;
                max-width: 100%;
            }
        }
        .input-property-forms {
            padding-left: 0px !important;
        }
    }
}
