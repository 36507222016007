.modification-tab-container {
    .modification-tab {
        margin-top: 15px;
        .modification-inputs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            .modification-input-form {
                width: 100%;
            }
            .modification-button-form {
                display: flex;
                justify-content: center;
                align-items: center;
                .modification-button {
                    height: max-content;
                }
            }
        }
    }
    .modification-add-button {
        margin-top: 15px;
    }
    padding-bottom: 10px;
}

.text-button-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.modification-button {
    height: auto; 
    max-width: 170px; 
    width: 100%; 
}

@media only screen and (max-width: 899px) {
    .modification-tab-container {
        .modification-tab {
            margin-top: 15px;
            .modification-inputs {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .modification-input-form {
                    width: 100%;
                    margin-top: 10px;
                }
                .modification-button-form {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .modification-button {
                        height: auto; 
                        max-width: 100px; 
                        width: 100%; 
                        margin: 0 auto;
                        // width: 100%;
                    }
                }
            }
        }
    }
}
