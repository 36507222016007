/* font converted using font-converter.net. thank you! */
@font-face {
    font-family: 'GTWalsheimProLight';
    src: url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.eot'); /* IE9 Compat Modes */
    src:
        url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.otf') format('opentype'),
        /* Open Type Font */ url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.svg') format('svg'),
        /* Legacy iOS */ url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.woff') format('woff'),
        /* Modern Browsers */ url('../assets/fonts/GTWalsheimProLight/fonts/GT-Walsheim-Pro-Light.woff2') format('woff2'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
